import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Win Star" keywords={[`Win Star`, `Brooklyn`, `restaurant`]} />
    <p
      style={{
        textAlign: 'center',
        marginBottom: 0,
        marginTop: 80,
      }}
    >
      taiwanese-american breakfast and dinner
    </p>
    <div style={{ maxWidth: `500px`, marginBottom: `1.45rem`, margin: 'auto' }}>
      <Image />
    </div>

    <p style={{ width: '100%', textAlign: 'center' }}>
      <a
        style={{ color: 'black' }}
        href="https://www.instagram.com/winstarbrooklyn/"
        target="_blank"
      >
        @winstarbrooklyn
      </a>
    </p>
    <p
      style={{
        textAlign: 'center',
      }}
    >
      164 Graham Ave
      <br />
      Brooklyn, NY 11206
    </p>
  </Layout>
)

export default IndexPage
